import Vue from 'vue'
import Router from 'vue-router'

// Vue.use(Router)
//
// // 公共路由
// export const constantRoutes = [
//   {
//     path: '/',
//     component:HomeView,
//     hidden: true,
//     name: 'HomeView'
//   },
//   {
//     path: '/about',
//     component:AboutView,
//     hidden: true,
//     name: 'AboutView'
//   },
//   {
//     path: '/user',
//     component:WechatUser,
//     hidden: true,
//     name: 'WechatUser'
//   },
// ]
// export default new Router({
//   mode: 'history', // 去掉url中的#
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes,
//   base: process.env.BASE_URL,
// })
Vue.use(Router)
const routes = [
    {
        path: '/',
        name: 'About',
        component: ()=>import('../components/HelloWorld')
    },
    {
        path: '/registerWechat',
        name: 'WechatUser',
        component: ()=>import('../components/WechatUser')
    },
    {
        path: '/showCase',
        name: 'ShowCase',
        component: ()=>import('../components/ShowCase')
    },
    {
        path: '/detailsShow',
        name: 'detailsShow',
        component: ()=>import('../components/DetailsShow')
    },
]

const router = new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    base : process.env.BASE_URL,
    routes
})

export default router
