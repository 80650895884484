import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'


Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from "axios";
Vue.prototype.$axios = axios;
Vue.prototype.$httpUrl = 'http://localhost:8080'
axios.defaults.headers.post['Access-Control-Allow-Origin']='*';

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'MYk7KQxnu8ExZ5rmhIfyrLBqxb0WcawC'
})



